/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import classNames from "classnames";
import {
  Apartment,
  CalendarMonthOutlined,
  Language,
  Search,
  SellRounded,
} from "@mui/icons-material";
import Rating from "../rating";
import { Tour } from "../../interface/model/tour";
import { useEffect, useState } from "react";
import { displayMoney } from "../../helper/display";
import Carousel from "../carousel";
import { ROUTE } from "../../config";
import useGo from "../../helper/use-go";
import useDevices from "../../helper/use-devices";

interface CardTourItemProps {
  className?: string;
  data: Tour;
  index: number;
  isRating?: boolean;
  isMiniScreen?: boolean;
  link?: string;
  getAQuote?: (value: Tour) => void;
}
export function CardTourItem({
  className,
  data,
  index,
  isRating,
  isMiniScreen,
  link,
  getAQuote,
}: CardTourItemProps) {
  const go = useGo();
  const [tour, setTour] = useState<Tour>();

  useEffect(() => {
    data && setTour(data);
  }, [data]);

  return (
    <div className={classNames("my-2 px-1", className)} >
      {tour && (
        <div className={classNames("card", "bg-base-100 max-w-md shadow-lg my-5 lg:my-0 mx-auto")}>
          {/* Image */}
          <figure className="m-auto">
            {/* tag */}
            {tour.discount > 0 && (
              <div className={classNames("sale-tag-container", "w-full")}>
                <div className={classNames(
                  "sale-tag",
                  "absolute py-1 px-5 top-5",
                  // "bg-blue-900 text-white"
                  "nst-bg text-white",
                )}>
                  <SellRounded className="mr-1" style={{width: "1rem", height: "1rem"}} />
                  Save up to {displayMoney(tour?.discount)}฿
                </div>
              </div>
            )}

            {/* image */}
            <img
              src={tour?.image_cover}
              alt={"recommended-" + index}
              className="w-full"
            />

            {/* quick view */}
            <button
              type="button"
              className={classNames(
                "btn-quick-view",
                "btn left-5 min-h-3 max-h-10 nst-text absolute",
                // isMiniScreen && "top-1/4",
                // !isMiniScreen && "top-1/3"
              )}
              style={{
                top: isMiniScreen ? "27%" : "33.33%"
              }}
              onClick={() => link && go(link)}
            >
              <Search style={{width: "1.3rem", height: "1.3rem"}} />
              Quick View
            </button>
          </figure>

          {/* Content */}
          <div className="card-body px-5">
            {/* With rating */}
            {
              isRating && (
                <>
                  <div className="grid grid-cols-2">
                    {/* Rating */}
                    <Rating
                      rating={tour?.rating}
                      name={"rating-" + index}
                      className="w-48 mb-2"
                    />

                    {/* Travel type */}
                    <div className="rounded-md bg-emerald-100 nst-text max-h-8 text-xs align-middle absolute right-5 mt-3 py-1 px-2">
                      {tour?.travel_type}
                    </div>
                  </div>

                  {/* Title */}
                  <h2 className="card-title mb-2 min-h-[56px]">
                    {tour?.title}
                  </h2>
                </>
              )
            }

            {/* No rating */}
            {
              !isRating && (
                <div className="">
                  {/* Travel type */}
                  <div className="rounded-md bg-emerald-100 nst-text max-h-8 text-xs align-middle absolute right-5 py-1 px-2 -mt-4">
                    {tour?.travel_type}
                  </div>

                  {/* Title */}
                  <h2 className="card-title my-4 text-left min-h-[56px]">
                    {tour?.title}
                  </h2>
                </div>
              )
            }

            {/* Includes */}
            <div className="includes grid grid-cols-3 mb-2">
              <div className="includes-item text-center" style={{fontSize: isMiniScreen ? "9px" : "11px"}}>
                <CalendarMonthOutlined
                  className="mb-1"
                  style={{
                    width: isMiniScreen ? "1rem" : "1.25rem",
                    height: isMiniScreen ? "1rem" : "1.25rem"
                  }}
                />
                <span>
                  {tour?.travel_period?.days} Days
                  {" "}
                  {tour?.travel_period?.nights} Nights
                </span>
              </div>

              <div className="includes-item text-center" style={{fontSize: isMiniScreen ? "9px" : "11px"}}>
                <Apartment
                  className="mb-1"
                  style={{
                    width: isMiniScreen ? "1rem" : "1.25rem",
                    height: isMiniScreen ? "1rem" : "1.25rem"
                  }}
                />
                <span>
                  {tour?.locations} Locations
                </span>
              </div>

              <div className="includes-item text-center" style={{fontSize: isMiniScreen ? "9px" : "11px"}}>
                <Language
                  className="mb-1"
                  style={{
                    width: isMiniScreen ? "1rem" : "1.25rem",
                    height: isMiniScreen ? "1rem" : "1.25rem"
                  }}
                />
                <span>
                  {tour?.countries} Country
                </span>
              </div>
            </div>

            {/* Detail */}
            <span className={classNames(
              "text-xs text-justify line-clamp-4",
              isMiniScreen && "max-h-20"
            )}>
              { tour?.detail }
            </span>

            {/* Prices & Booking */}
            <div className={classNames("prices-and-booking", "mt-5 grid grid-cols-2 grid-rows-2")} >
              <div className={classNames("was-prices", "align-middle mt-8")}>
                {/* Was price */}
                {
                  tour?.prices?.was ? (
                    <div className="price-was text-left text-xs">
                      <s>Was ฿{displayMoney(tour?.prices?.was)}</s>
                    </div>
                  ) : ""
                }
              </div>

              {/* View tour */}
              <div className={classNames("view-tour", "right-0")}>
                <button
                  className={classNames(
                    "btn-booking btn-view-tour",
                    "btn my-1 w-full rounded-3xl",
                    "nst-btn"
                    // "bg-blue-800 text-white",
                    // "hover:bg-white hover:text-blue-800 hover:border-blue-800"
                  )}
                  onClick={() => link && go(link)}
                >
                  VIEW TOUR
                </button>
              </div>

              {/* Price discount */}
              <div className={classNames("prices", "align-middle")}>
                <div className="price-normal text-left mt-1">
                  From{" "}
                  <span className="text-2xl font-semibold">
                    ฿{displayMoney(tour?.prices?.from)}
                  </span>
                </div>
              </div>

              {/* Booking */}
              <div className="booking">
                <button
                  className={classNames(
                    "btn-booking btn-get-a-quote",
                    "btn my-1 w-full rounded-3xl",
                    "nst-btn-outline"
                    // "bg-white text-blue-800 border-blue-800",
                    // "hover:bg-blue-800 hover:text-white"
                  )}
                  onClick={() => getAQuote && getAQuote(tour)}
                >
                  GET A QUOTE
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface CardToursProps {
  data: Tour[];
  className?: string;
  isAnimate?: boolean;
  isSlide?: boolean;
  isRating?: boolean;
  isMiniScreen?: boolean;
  getAQuote?: (value: Tour) => void;
}
export default function CardTours({
  data,
  className,
  isAnimate,
  isSlide,
  isRating,
  isMiniScreen,
  getAQuote,
}: CardToursProps) {
  const { isMobileDevice, isTabletDevice } = useDevices();
  const [tours, setTours] = useState<Tour[]>([]);

  useEffect(() => {
    data && setTours(data);
  }, [data]);

  return (
    <>
      {
        tours &&
        tours?.length > 0 &&
        isSlide ? (
          <div
            className={classNames(
              "card-wrapper",
              "w-full m-auto my-10",
            )}
            data-aos={isAnimate ? "fade-up" : ""}
          >
            <Carousel
              className="rounded-box w-full py-10 lg:w-4/5"
              totalItems={tours?.length || 0}
            >
              {tours?.map((tour: Tour, index: number) => (
                <div
                  key={`card-item-${index}`}
                  className={classNames(
                    "carousel-item",
                    (isMobileDevice || isTabletDevice) && "justify-center",
                  )}
                >
                  <CardTourItem
                    data={tour}
                    index={index}
                    isRating={isRating}
                    isMiniScreen={isMiniScreen}
                    link={`${ROUTE.DESTINATION}/${tour.tourCountryId}/tour/${tour.id}`}
                    getAQuote={getAQuote}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div
            className={classNames(
              "card-wrapper",
              "lg:grid lg:grid-cols-3 w-full lg:w-4/5 m-auto my-10 py-10",
              className
            )}
            key={`card-tours-${Math.random()}`}
            data-aos={isAnimate ? "fade-up" : ""}
          >
            {tours?.map((tour: Tour, index: number) => (
              <CardTourItem
                data={tour}
                index={index}
                key={`card-item-${index}`}
                isRating={isRating}
                isMiniScreen={isMiniScreen}
                link={`${ROUTE.DESTINATION}/${tour.tourCountryId}/tour/${tour.id}`}
                getAQuote={getAQuote}
              />
            ))}
          </div>
        )
      }
    </>
  )
}