/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import { useLoadingContext } from "../../../context/loading";
import useGo from "../../../helper/use-go";
import "./style.css";
import { useEffect, useState } from "react";
import { TourService } from "../../../api/tour";
import { DELAY, RESPONSE_STATUS, ROUTE } from "../../../config";
import classNames from "classnames";
import BreadCrumb, { BreadCrumbItem } from "../../../components/breadcrumb";
import CoverPage from "../../../components/cover-page";
import CardTourHorizontal from "../../../components/card-tour-horiizontal";
import { cloneDeep } from "lodash";
import { FilterBy } from "../../../interface/model/filter";
import { Tour } from "../../../interface/model/tour";
import { CardTourItem } from "../../../components/card-tour";
import { randomNumber, shuffleArray } from "../../../helper";
import ModalGetAQuote from "../../../components/modal-get-a-quote";
import { GetAQuoteForm } from "../../../interface/component/modal-get-a-quote";
import useDevices from "../../../helper/use-devices";

export default function DestinationDetail() {
  const { setLoading } = useLoadingContext();
  const go = useGo();
  const { id } = useParams();
  const { isDesktopDevice } = useDevices();

  const [search, setSearch] = useState<string>("");
  const [tour, setTour] = useState<any | undefined>({});
  const [tours, setTours] = useState<any | undefined>([]);

  const [highlightTour, setHighlightTour] = useState<Tour>();
  const [alsoLikeTours, setAlsoLikeTours] = useState<Tour[]>();

  const [modalGetAQuote, setModalGetAQuoteData] = useState<Tour>();
  const [openModalGetAQuote, setOpenModalGetAQuote] = useState<boolean>(false);

  useEffect(() => {
    id && handleGetDestinationDetail();
  }, [id]);

  const handleGetDestinationDetail = async () => {
    if (!id) return;

    const getById = async () => {
      setLoading(true);

      const service = TourService();
      const res = await service.getTourCountryById(id);
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        if (res?.data) {
          setTour(cloneDeep(res?.data) || {});
        }
        if (res?.data?.tours) {
          const data = cloneDeep(res?.data?.tours);
          const dataLang = data?.length - 1;
          setTours(cloneDeep(tours) || []);
          setHighlightTour(cloneDeep(data[randomNumber(dataLang)]));

          setAlsoLikeTours(cloneDeep(shuffleArray(data)));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getById();
  };

  // search
  useEffect(() => {
    id && handleSearchTours();
  }, [search]);

  const handleSearchTours = async () => {
    if (!id) return;

    setLoading(true);
    const getTours = async () => {
      const service = TourService();
      const filter: FilterBy = {
        search,
      };
      const res = await service.getToursByCountryId(id, filter);
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setTours(cloneDeep(res?.data || []));
        clearLoading();
      } else {
        clearLoading();
      }
    };
    getTours();
  };

  const clearLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, DELAY);
  }

  const toggleModalGetAQuote = (tour: Tour) => {
    setModalGetAQuoteData(tour);
    setOpenModalGetAQuote((prev) => prev = !prev);
  }

  const saveGetAQuote = (form: GetAQuoteForm) => {
    // console.log("saveGetAQuote : ", form);
  }

  return (
    <>
      <div
        className={classNames(
          "destination-detail-container"
        )}
      >
        <BreadCrumb
          className={classNames(
            "bg-white text-gray-800 mt-[70px] z-10",
            "lg:mt-[74.5px] w-full absolute",
          )}
        >
          <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
          <BreadCrumbItem
            title="Destination"
            onClick={() => go(ROUTE.DESTINATION)}
          />
          <BreadCrumbItem title={tour?.title} isActive />
        </BreadCrumb>
      </div>

      {/* Cover */}
      {tour && (
        <CoverPage
          // image="/asset/images/mock/cover-home.png"
          title={tour?.title}
          detailPosition={"right"}
          viewLink={ROUTE.DESTINATION}
          isAnimate
          heightDetail="lg:h-1/4"
          inputSearch={(value: string) => setSearch(value)}
          onSearch={() => handleSearchTours()}
        />
      )}

      {/* Tours */}
      <div className="w-full lg:w-5/6 left-0 right-0 relative m-auto mt-28 mb-20 px-1 lg:px-0">
        {/* Title */}
        <section className="my-20">
          <h1 className="text-xl p-5 font-semibold">{tour?.description}</h1>
        </section>

        {/* Tour */}
        <section className="my-7 w-full lg:w-10/12 m-auto">
          {
            tours && tours?.map((item: any, index: any) => (
              <div key={`tour-${tour?.title}-${item?.id}-${index}`}>
                {
                  isDesktopDevice ? (
                    <CardTourHorizontal
                      data={item}
                      index={index}
                      // isRating
                      link={`${ROUTE.DESTINATION}/${item.tourCountryId}/tour/${item.id}`}
                      getAQuote={(value: Tour) => toggleModalGetAQuote(value)}
                    />
                  ) : (
                    <CardTourItem
                      data={item}
                      index={index}
                      // isRating
                      link={`${ROUTE.DESTINATION}/${item.tourCountryId}/tour/${item.id}`}
                      getAQuote={(value: Tour) => toggleModalGetAQuote(value)}
                    />
                  )
                }
                
              </div>
            ))
          }
        </section>

        {/* Highlight */}
        <section className="my-10 cursor-default">
          <div className="min-h-60 rounded-2xl items-center text-white grid" style={{backgroundImage: `url(${highlightTour?.image_highlight})`}}>
            <div className="text-lg lg:text-2xl">
              {/* Title */}
              <p className="font-semibold text-2xl lg:text-4xl mb-4">
                {highlightTour?.title}
              </p>

              {/* Period */}
              <p>
                {tour?.title?.toUpperCase()}
                {" "} - {" "}
                {highlightTour?.travel_period?.days}
                {" "}Days{" "}
                {highlightTour?.travel_period?.nights}
                {" "}Nights
              </p>
            </div>

          </div>
        </section>

        {/* You might also like */}
        <section className="my-10">
          <div className={classNames(
            "bg-[#354e57] rounded-lg p-5",
            isDesktopDevice && "grid grid-cols-5",

          )}>
            {/* Text */}
            <div className="text-white text-left font-semibold pl-3 mt-10 mb-5">
              <h2 className="text-4xl mb-5">You might also like</h2>
              <p>However, assuming you have around and a desire for a diverse experience, here's a suggested itinerary</p>
            </div>

            {/* Card */}
            <div className={classNames(
              // "lg:col-span-4 lg:grid lg:grid-cols-3"
              "mx-auto",
              isDesktopDevice && "col-span-4 grid grid-cols-3"
            )}>
              {
                alsoLikeTours && alsoLikeTours?.slice(0, 3)?.map((item: Tour, index: number) => (
                  <CardTourItem
                    className="min-h-[327px] mx-auto"
                    data={item}
                    index={index}
                    key={`also-like-tour-${item?.detail}-${index}`}
                    isMiniScreen
                    link={`${ROUTE.DESTINATION}/${item.tourCountryId}/tour/${item.id}`}
                    getAQuote={(value: Tour) => toggleModalGetAQuote(value)}
                  />
                )
                )
              }
            </div>
          </div>
        </section>

      </div>

      {/* Get a Quote */}
      <ModalGetAQuote
        className="min-w-[90%] lg:min-w-[80%] pb-8"
        data={modalGetAQuote}
        isOpen={openModalGetAQuote}
        setIsOpen={() => setOpenModalGetAQuote(false)}
        onConfirm={(form: GetAQuoteForm) => saveGetAQuote(form)}
      />
    </>
  );
}
