/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import "./style.css";
import Modal from "../modal";
import { useEffect, useState } from "react";
import {
  GetAQuoteForm,
  GetAQuoteFormValid,
  mockGetAQuoteForm,
  mockGetAQuoteFormValid,
} from "../../interface/component/modal-get-a-quote";
import { Tour } from "../../interface/model/tour";
import CardTourMini from "../card-tour-mini";
import SelectBox from "../select";
import dayjs, { Dayjs } from "dayjs";
import { cloneDeep } from "lodash";
import { isMobile } from "react-device-detect";
import { TourService } from "../../api/tour";
import { MESSAGE_CONTACT, RESPONSE_STATUS } from "../../config";
import { validateEmail, validateRequired } from "../../helper";

interface ModalGetAQuoteProps {
  className?: string;
  data?: Tour | undefined;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
  onConfirm?: (value: any) => void;
}

export default function ModalGetAQuote({
  className,
  isOpen,
  data,
  setIsOpen,
  onConfirm,
}: ModalGetAQuoteProps) {
  const [form, setForm] = useState<GetAQuoteForm>(cloneDeep(mockGetAQuoteForm));
  const [formValid, setFormValid] = useState<GetAQuoteFormValid>(cloneDeep(mockGetAQuoteFormValid));

  const [travelDate, setTravelDate] = useState<any[]>([]);
  const [selectedTravelDate, setSelectedTravelDate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (isOpen) {
      handleSetTravelDate();
    } else {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data) {
      mockGetAQuoteForm.tour = data;
      setForm(cloneDeep(mockGetAQuoteForm));
      setFormValid(cloneDeep(mockGetAQuoteFormValid));
    }
  }, [data]);

  useEffect(() => {
    if (isSubmit) {
      validForm();
    }
  }, [form]);

  const handleSetTravelDate = () => {
    let months: any[] = [];
    const current: Dayjs = dayjs();

    for (let i = 0; i < 12; i++) {
      months.push({
        label: dayjs(current.add(i, "months")).format("MMM YYYY"),
        value: dayjs(current.add(i, "months")).format("YYYY-MM") + "-01",
      });
    }

    setTravelDate(months);
  };

  // Change value
  const onChangeTravelDate = (option: any) => {
    setForm((prevForm) => ({
      ...prevForm,
      travelDate: dayjs(option.value).toJSON(),
    }));
    setSelectedTravelDate(option);
  };

  const onToggleAccept = (checked: boolean) => {
    setForm((prevForm) => ({
      ...prevForm,
      acceptPolicy: checked,
    }));
  };

  const onChangeForm = (value: string, name: string) => {
    setForm((provForm) => ({
      ...provForm,
      [name]: value,
    }));
  };

  // Submit
  const submitForm = async () => {
    setIsSubmit(true);

    if (!validForm()) {
      return;
    }

    if (onConfirm) {
      setIsLoading(true);

      // Call api
      const getAQuote = async () => {
        const payload = {
          ...form,
          tour: data,
        };
        const service = TourService();
        const res = await service.contactGetAQuote(payload);
        if (res && res.status === RESPONSE_STATUS.SUCCESS) {
          setMessage(MESSAGE_CONTACT.GET_A_QUOTE_SUCCESS);
          setIsError(false);
          setIsLoading(false);
          resetForm();
          // setTimeout(() => {
          //   setIsOpen && setIsOpen(false);
          // }, 5000);
        } else {
          setMessage(MESSAGE_CONTACT.GET_A_QUOTE_ERROR);
          setIsError(true);
          setIsLoading(false);
        }
      };
      getAQuote();
    }
  };

  const resetForm = () => {
    mockGetAQuoteForm.travelDate = "";
    setForm(cloneDeep(mockGetAQuoteForm));
    setFormValid(cloneDeep(mockGetAQuoteFormValid));
    setSelectedTravelDate(null);

    setMessage("");
    setIsError(false);
    setIsSubmit(false);

    if (setIsOpen) {
      setIsOpen(false);
    }
  };

  const validForm = (): boolean => {
    let isValid: boolean = true;

    // Travel date
    isValid = validateRequired(form.travelDate, (valid: string) => {
      formValid.travelDateValid = valid ? "Travel month is required." : "";
    })
      ? isValid
      : false;

    // First name
    isValid = validateRequired(form.firstName, (valid: string) => {
      formValid.firstNameValid = valid ? "First name is requried." : "";
    })
      ? isValid
      : false;

    // Last name
    isValid = validateRequired(form.lastName, (valid: string) => {
      formValid.lastNameValid = valid ? "Last name is requried." : "";
    })
      ? isValid
      : false;

    // Phone number
    isValid = validateRequired(form.phoneNumber, (valid: string) => {
      formValid.phoneNumberValid = valid ? "Phone number is requried." : "";
    })
      ? isValid
      : false;

    // Email
    isValid = validateEmail(form.email, (valid: string) => {
      formValid.emailValid =
        valid && valid?.includes("format")
          ? valid
          : valid
            ? "Email is required."
            : "";
    })
      ? isValid
      : false;

    // Accept
    if (!form.acceptPolicy) {
      formValid.acceptPolicyValid = "Please accept our vacations privacy policy.";
      isValid = false;
    } else {
      formValid.acceptPolicyValid = "";
    }

    setFormValid(cloneDeep(formValid));
    return isValid;
  };

  return (
    <Modal
      className={classNames(
        "modal-get-a-quote-modal-container"
        // className
      )}
      modalBoxClass={className}
      title="GET A QUOTE"
      isOpen={isOpen}
      setIsOpen={resetForm}
      btnCancel="CANCEL"
      onCancel={resetForm}
      btnConfirm="SUBMIT"
      onConfirm={submitForm}
      disableBtn={isLoading}
    >
      <div className="w-11/12 lg:w-10/12 mx-auto mt-10">
        {/* Mini card tour */}
        {data && (
          <CardTourMini
            data={data}
            // isRating
          />
        )}

        {/* Form */}
        <div className="form mt-10 text-left">
          <div className="lg:grid lg:grid-cols-2 lg:gap-4 mb-5">
            {/* Travel date */}
            <label htmlFor="travel-dates" className="lg:col-span-2">
              Select your preferred travel dates *
            </label>

            <SelectBox
              className="cursor-pointer lg:col-span-2 my-3 lg:my-0"
              placeholder="Select your preferred travel month *"
              selectedTextAlign="left"
              value={selectedTravelDate}
              options={travelDate}
              onChange={onChangeTravelDate}
              error={formValid.travelDateValid}
            />

            {/* Information */}
            {/* Firstname */}
            <div className="firstname">
              <input
                type="text"
                className={classNames(
                  "input input-bordered w-full mb-3 lg:mb-0",
                  formValid?.firstNameValid &&
                    "border-red-500 rounded-md border-[1px]"
                )}
                placeholder="First Name *"
                name="firstName"
                style={{
                  fontSize: isMobile ? "0.8rem" : "1rem",
                }}
                value={form.firstName}
                onChange={(event: any) => onChangeForm(event.target.value, 'firstName')}
              />
              {formValid?.firstNameValid && (
                <div className="text-red-600 text-sm w-full cursor-default mt-1">
                  {formValid?.firstNameValid}
                </div>
              )}
            </div>

            {/* Lastname */}
            <div className="lastname">
              <input
                type="text"
                className={classNames(
                  "input input-bordered w-full mb-3 lg:mb-0",
                  formValid?.lastNameValid &&
                    "border-red-500 rounded-md border-[1px]"
                )}
                placeholder="Last Name *"
                name="lastName"
                style={{
                  fontSize: isMobile ? "0.8rem" : "1rem",
                }}
                value={form.lastName}
                onChange={(event: any) => onChangeForm(event.target.value, 'lastName')}
              />
              {formValid?.lastNameValid && (
                <div className="text-red-600 text-sm w-full cursor-default mt-1">
                  {formValid?.lastNameValid}
                </div>
              )}
            </div>

            {/* Phone number */}
            <div className="phone-number">
              <input
                type="text"
                className={classNames(
                  "input input-bordered w-full mb-3 lg:mb-0",
                  formValid?.phoneNumberValid &&
                    "border-red-500 rounded-md border-[1px]"
                )}
                placeholder="Phone Number *"
                name="phoneNumber"
                style={{
                  fontSize: isMobile ? "0.8rem" : "1rem",
                }}
                value={form.phoneNumber}
                onChange={(event: any) => onChangeForm(event.target.value, 'phoneNumber')}
              />
              {formValid?.phoneNumberValid && (
                <div className="text-red-600 text-sm w-full cursor-default mt-1">
                  {formValid?.phoneNumberValid}
                </div>
              )}
            </div>

            {/* Email */}
            <div className="email">
              <input
                type="text"
                className={classNames(
                  "input input-bordered w-full mb-3 lg:mb-0",
                  formValid?.emailValid &&
                    "border-red-500 rounded-md border-[1px]"
                )}
                placeholder="E-Mail *"
                name="email"
                style={{
                  fontSize: isMobile ? "0.8rem" : "1rem",
                }}
                value={form.email}
                onChange={(event: any) => onChangeForm(event.target.value, 'email')}
              />
              {formValid?.emailValid && (
                <div className="text-red-600 text-sm w-full cursor-default mt-1">
                  {formValid?.emailValid}
                </div>
              )}
            </div>

            {/* Noti confirm */}
            <div className="inline-block col-span-2 mx-auto mt-2">
              <input
                type="checkbox"
                className="checkbox checkbox-sm w-full"
                checked={form.acceptPolicy}
                onChange={(event: any) => onToggleAccept(event.target.checked)}
              />
              <label
                htmlFor="noti-confirm"
                className={classNames(
                  "col-span-2 pl-3 text-[0.8rem] lg:text-[1rem]",
                  formValid?.acceptPolicyValid && "text-red-600"
                )}
              >
                * Please confirm you have read and accept the Insight Vacations
                Privacy Policy.
              </label>
            </div>

            {/* Additional Information */}
            <div className="additional-container my-4 col-span-2">
              <span className="font-bold text-xl text-gray-800">
                Additional Information
              </span>
              <textarea
                name="additionalInformation"
                id="additional-information"
                className="textarea textarea-bordered h-28 mt-4 w-[100%]"
                value={form.message}
                onChange={(event: any) => onChangeForm(event.target.value, 'message')}
              ></textarea>
            </div>

            {/* Message */}
            {message && (
              <div className={classNames(
                "message my-4 p-5 col-span-2",
                !isError && "bg-green-50 border-green-600 border-[1px] rounded-md text-sm text-green-600",
                isError && "bg-red-50 border-red-600 border-[1px] rounded-md text-sm text-red-600",
              )}>
                {message}
              </div>
            )}

          </div>
        </div>
      </div>
    </Modal>
  );
}
