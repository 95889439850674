import './App.css';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTE } from './config';
import Layout from './layout';
import Home from './app/home';
import AboutUs from './app/about-us';
import Destinations from './app/destinations';
import ContextProvider from './context';
import NotFound from './app/not-found';
import Services from './app/services';
import ContactUs from './app/contact-us';
import ServiceDetail from './app/services/detail';
import DestinationDetail from './app/destinations/detail';
import DestinationTour from './app/destinations/tour';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  const router = createBrowserRouter([
    {
      element: (
        <Layout />
      ),
      errorElement: <>Error page!</>,
      children: [
        {
          path: ROUTE.HOME,
          element: <Home />,
        },
        {
          path: ROUTE.DESTINATION,
          element: <Destinations />,
        },
        {
          path: `${ROUTE.DESTINATION}/:id`,
          element: <DestinationDetail />,
        },
        {
          path: `${ROUTE.DESTINATION}/:id/tour/:programId`,
          element: <DestinationTour />,
        },
        {
          path: `${ROUTE.SERVICE}`,
          element: <Services />
        },
        {
          path: `${ROUTE.SERVICE}/:id`,
          element: <ServiceDetail />
        },
        {
          path: ROUTE.ABOUT_US,
          element: <AboutUs />,
        },
        {
          path: ROUTE.CONTACT_US,
          element: <ContactUs />,
        },
      ]
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <div className="App">
      <ContextProvider>
        <RouterProvider router={router} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          // theme="colored"
        />
      </ContextProvider>
    </div>
  );
}

export default App;
