import classNames from "classnames";
import "./style.css";
import { Tour } from "../../interface/model/tour";
import Rating from "../rating";
import { displayUnitNumber } from "../../helper";

interface CardTourMiniProps {
  className?: string;
  data: Tour;
  isRating?: boolean;
}

export default function CardTourMini({
  className,
  data,
  isRating,
}: CardTourMiniProps) {
  return (
    <div className={classNames(
      "my-5",
      className
    )}>
      <div className="grid grid-cols-2 lg:grid-cols-6 rounded-lg shadow-md">
        {/* Image */}
        <div className="image-container col-span-2">
          <img className="rounded-t-md lg:rounded-l-md" src={data?.image} alt={`card-mini-tour-${data?.title}`} />
        </div>

        {/* Content */}
        <div className="content-container col-span-4 text-left pl-3 pb-2 lg:pb-0">
          {/* Rating */}
          {
            isRating && (
              <div className="review-container flex items-center">
                <Rating
                  rating={data?.rating}
                  name={"rating-" + data?.id}
                  className="w-20"
                />

                <div className="text-md ml-3 text-gray-500 font-semibold inline-block">
                  <span className="font-bold text-sm">{data?.rating}</span>
                  {" "}

                  {
                    data?.reviewCount ? (
                      <div className="inline-block">
                        <span className="font-bold">
                          {displayUnitNumber(data?.reviewCount)}
                        </span>
                        {" "}
                        Independent Reviews
                      </div>
                    ) : ""
                  }
                </div>
              </div>
            )
          }

          {/* Title */}
          <div className={classNames(
              "title-container", !isRating && "mt-3"
            )}
          >
            <span className="font-semibold text-sm">
              {data?.title}
            </span>
          </div>

          {/* Detail */}
          <div className="detail-container">
            <span className="text-xs">
              {data?.detail}
            </span>
          </div>

        </div>
      </div>
    </div>
  )
}