export interface ContactForm {
  travelDate: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  acceptPolicy: boolean;
  message?: string;
}

export const mockContactForm: ContactForm = {
  travelDate: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  acceptPolicy: false,
  message: ""
}

export interface ContactFormValid {
  travelDateValid: string;
  firstNameValid: string;
  lastNameValid: string;
  phoneNumberValid: string;
  emailValid: string;
  acceptPolicyValid: string;
  messageValid?: string;
}

export const mockContactFormValid: ContactFormValid = {
  travelDateValid: "",
  firstNameValid: "",
  lastNameValid: "",
  phoneNumberValid: "",
  emailValid: "",
  acceptPolicyValid: "",
  messageValid: ""
}
