/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import CardTours from "../../../../components/card-tour";
import { Tour } from "../../../../interface/model/tour";
import { useEffect, useState } from "react";
import { useLoadingContext } from "../../../../context/loading";
import { TourService } from "../../../../api/tour";
import { RESPONSE_STATUS, ROUTE } from "../../../../config";
import { cloneDeep } from "lodash";
import ModalGetAQuote from "../../../../components/modal-get-a-quote";
import { GetAQuoteForm } from "../../../../interface/component/modal-get-a-quote";
import { randomNumber } from "../../../../helper";
import useGo from "../../../../helper/use-go";

interface HomeRecommendedProps {
  className?: string;
  isAnimate?: boolean;
}

export default function HomeRecommended({
  className,
  isAnimate,
}: HomeRecommendedProps) {
  const go = useGo();
  const { setLoading } = useLoadingContext();

  const [tours, setTours] = useState<Tour[]>([]);
  const [highlightTour, setHighlightTour] = useState<Tour>();

  const [modalGetAQuote, setModalGetAQuoteData] = useState<Tour>();
  const [openModalGetAQuote, setOpenModalGetAQuote] = useState<boolean>(false);

  useEffect(() => {
    handleGetTours();
  }, []);

  const handleGetTours = async () => {
    const getTours = async () => {
      setLoading(true);

      const service = TourService();
      const res = await service.getToursRecommended();
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setTours(cloneDeep(res.data));

        const dataLang = res?.data?.length - 1;
        setHighlightTour(cloneDeep(res?.data[randomNumber(dataLang)]));

        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getTours();
  }

  const toggleModalGetAQuote = (tour: Tour) => {
    setModalGetAQuoteData(tour);
    setOpenModalGetAQuote((prev) => prev = !prev);
  }

  const saveGetAQuote = (form: GetAQuoteForm) => {
    // console.log("saveGetAQuote : ", form);
  }

  return (
    <>
      <div
        className={classNames(
          "recommended-container",
          "mt-28",
          className
        )}
      >
        {/* Title */}
        <h1
          className={classNames(
            "title",
            "font-semibold text-2xl text-gray-600 w-4/5 m-auto"
          )}
          data-aos={isAnimate ? "fade-up" : "fade"}
        >
          RECOMMENDED TOURS
        </h1>

        {/* Display 3 Cards and slide */}
        <CardTours
          data={tours}
          isAnimate={isAnimate}
          // isSlide={!isMobile}
          isSlide
          // isRating
          getAQuote={(value: Tour) => toggleModalGetAQuote(value)}
        />

        {/* Highlight */}
        <div
          className={classNames("highlight-wrapper", "w-full lg:w-4/5 my-10 min-h-80 m-auto")}
          data-aos={isAnimate ? "fade-up" : "fade"}
        >
          <div
            className={classNames("highlight", "rounded-xl p-16 mx-2 lg:mx-0")}
            style={{
              backgroundImage: "url(/asset/images/mock/home-highlight.png)",
            }}
          >
            <div className={classNames("content", "lg:text-left")}>
              <div className={classNames(
                "title title-1",
                "mb-2 mx-0 text-3xl font-semibold text-white",
                "text-lg lg:text-3xl w-full",
              )}>
                {highlightTour?.title}
              </div>

              {/* <div className={classNames(
                "title title-1",
                "mb-2 mx-0 text-3xl font-semibold text-white",
                "text-lg lg:text-3xl w-full",
              )}>
                Highlight of
              </div>
              <div className={classNames(
                "title title-2",
                "mb-2 mx-0 text-3xl font-semibold text-white",
                "text-2xl lg:text-5xl w-full",
              )}>
                Hanoi Vietname
              </div> */}
              <button
                className={classNames(
                  "btn-find-out-more",
                  "btn rounded-3xl mt-3 w-3/4 lg:w-1/4 border-none",
                  "nst-btn border-white",
                  // "bg-blue-800 text-white",
                  // "hover:border-blue-800 hover:bg-white hover:text-blue-800"
                )}
                onClick={() => go(`${ROUTE.DESTINATION}/${highlightTour?.tourCountryId}/tour/${highlightTour?.id}`)}
              >
                FIND OUT MORE
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Get a Quote */}
      <ModalGetAQuote
        className="min-w-[90%] lg:min-w-[80%] pb-8"
        data={modalGetAQuote}
        isOpen={openModalGetAQuote}
        setIsOpen={() => setOpenModalGetAQuote(false)}
        onConfirm={(form: GetAQuoteForm) => saveGetAQuote(form)}
      />
    </>
  );
}
