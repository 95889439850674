/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import "./style.css";
import Modal from "../modal";
import { useEffect, useState } from "react";
import { Tour } from "../../interface/model/tour";
import useGo from "../../helper/use-go";
import { useLoadingContext } from "../../context/loading";
import { TourService } from "../../api/tour";
import { RESPONSE_STATUS, ROUTE } from "../../config";
import { cloneDeep } from "lodash";

interface ModalSearchTourProps {
  className?: string;
  search?: string;
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
}

export default function ModalSearchTour({
  className,
  search,
  isOpen,
  setIsOpen,
}: ModalSearchTourProps) {
  const { setLoading } = useLoadingContext();
  const go = useGo();

  const [results, setResults] = useState<Tour[]>([]);

  useEffect(() => {
    isOpen && handleGetTour(search);
  }, [isOpen]);

  const handleGetTour = async (search: string = "") => {
    const getTour = async () => {
      setLoading(true);

      const service = TourService();
      const res = await service.searchTours({ search });
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setResults(cloneDeep(res.data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    getTour();
  }

  const highlightText = (text: string) => {
    if (!search || !search.trim()) return text;

    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <span className="text-[#018181] font-semibold" key={index}>{part}</span>
      ) : (
        part
      )
    );
  };

  return (
    <Modal
      className={classNames(
        "modal-search-tour-container",
      )}
      modalBoxClass={classNames(
        "w-11/12 lg:w-10/12 max-w-5xl pb-10",
        className
      )}
      title={`Search from : <span class='font-normal'>${search || 'All'}</span>`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      btnClose="Close"
      onClose={() => setIsOpen && setIsOpen(false)}
    >
      <div className="w-11/12 lg:w-10/12 mx-auto pb-5">
        {/* Title */}
        <div className="title-container my-5 text-left">
          Results: { results?.length || 0 } items
        </div>

        {/* Tours */}
        {
          results && results?.map((item: Tour, index: number) => (
            <div
              key={`search-tour-item-${index}`}
              className={classNames(
                "tour-item",
                "shadow-sm rounded-sm border-[1px] my-2 p-3 text-left",
                "hover:bg-gray-100 hover:cursor-pointer"
              )}
              onClick={() => go(`${ROUTE.DESTINATION}/${item.tourCountryId}/tour/${item.id}`)}
            >
              <div className="text-[1rem] font-semibold">
                { search ? highlightText(item?.title) : item?.title}
              </div>
              <div className="text-[0.75rem]">
                { search ? highlightText(item?.detail) : item?.detail}
              </div>
            </div>
          ))
        }
      </div>
    </Modal>
  )
}
