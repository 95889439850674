import { cloneDeep } from "lodash";
import { RESPONSE_STATUS } from "../config";
import { Tour } from "../interface/model/tour";
import { mockToursCambodia, mockToursCountries, mockToursIndoChina, mockToursLoas, mockToursMyanmar, mockToursThailand, mockToursVietnam } from "./data/tour";
import { FilterBy } from "../interface/model/filter";
import apiService from "./api-service";
import { GetAQuoteForm } from "../interface/component/modal-get-a-quote";

export const TourService = () => {
  const api = apiService();
  const path = "/tour";

  return {
    getToursCountries: (payload: any): Promise<any> => {
      // return api.get(`${path}s`, payload);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetToursCountries()
      });
    },
    getTourCountryById: (id: number | string): Promise<any> => {
      // return api.get(`${path}/${id}`);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetTourCountryById(id)
      });
    },
    getToursByCountryId: (id: number | string, filter: FilterBy): Promise<any> => {
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetToursByCountryId(id, filter)
      });
    },
    // Get program
    getTourProgramById: (id: number | string, programId: number | string): Promise<any> => {
      // return api.get(`${path}/${id}/${programId}`);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetTourById(id, programId),
        parent: mockGetTourById(id),
      });
    },
    // Recommended
    getToursRecommended: (): Promise<any> => {
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetToursRecommended()
      });
    },
    // Search tour
    searchTours: (payload: any): Promise<any> => {
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockSearchTours(payload?.search)
      });
    },
    // Get a quote
    contactGetAQuote: (payload: GetAQuoteForm): Promise<any> => {
      return api.post(`${path}/contact-get-a-quote`, payload);
    }
  }
}

const mockGetToursRecommended = () => {
  const list: Tour[] = [
    ...cloneDeep(mockToursThailand?.filter(r => r?.is_recommended)),
    ...cloneDeep(mockToursVietnam?.filter(r => r?.is_recommended)),
    ...cloneDeep(mockToursCambodia?.filter(r => r?.is_recommended)),
    ...cloneDeep(mockToursLoas?.filter(r => r?.is_recommended)),
    ...cloneDeep(mockToursMyanmar?.filter(r => r?.is_recommended)),
    ...cloneDeep(mockToursIndoChina?.filter(r => r?.is_recommended))
  ];

  return list;
}

const mockSearchTours = (search: string = "") => {
  const list: Tour[] = [
    ...cloneDeep(mockToursThailand),
    ...cloneDeep(mockToursVietnam),
    ...cloneDeep(mockToursCambodia),
    ...cloneDeep(mockToursLoas),
    ...cloneDeep(mockToursMyanmar),
    ...cloneDeep(mockToursIndoChina)
  ];

  return list.filter(
    (r: Tour) =>
      r?.title?.toLowerCase()?.includes(search?.toLowerCase()) ||
      r?.detail?.toLowerCase()?.includes(search?.toLowerCase())
  );
}

const mockGetToursCountries = () => {
  const list = cloneDeep(mockToursCountries);
  const result = list?.map((item: any) => {
    // delete item?.tours;
    return {
      id: item.id,
      title: item.title,
      description: item.description,
      image: item.image
    };
  });
  return result;
}

const mockGetTourCountryById = (id: number | string) => {
  if (id) {
    const list = cloneDeep(mockToursCountries);
    const detail = list?.find(r => +r.id === +id) || null;
    // delete detail?.tours;
    return detail;
  }
  
  return null;
}

const mockGetToursByCountryId = (id: number | string, filter: FilterBy) => {
  if (id) {
    const list = cloneDeep(mockToursCountries);
    const detail = list?.find(r => +r.id === +id) || null;
    // return detail?.tours;
    return detail?.tours?.filter((r: Tour) =>
      r.title?.toLowerCase()?.includes(filter?.search?.toLowerCase() || "")
    ) || [];  
  }

  return null;
}

const mockGetTourById = (id: number | string, programId?: number | string) => {
  if (id) {
    const list = cloneDeep(mockToursCountries);
    const detail = list?.find(r => +r.id === +id) || null;
    if (detail) {

      // Return parent data
      if (!programId) {
        return {
          id: detail?.id,
          title: detail?.title,
          description: detail?.description,
        }
      }

      // Return tour data
      const program = detail?.tours?.find(r => +r.id === +programId) || null;
      return program;
    }
  }

  return null
}
