import { ContactForm, ContactFormValid } from "../model/contact";
import { Tour } from "../model/tour";

export interface GetAQuoteForm extends ContactForm {
  tour?: Tour;
}

export const mockGetAQuoteForm: GetAQuoteForm = {
  travelDate: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  acceptPolicy: false,
  message: "",
}

export interface GetAQuoteFormValid extends ContactFormValid {
  tourValid?: string;
}

export const mockGetAQuoteFormValid: GetAQuoteFormValid = {
  travelDateValid: "",
  firstNameValid: "",
  lastNameValid: "",
  phoneNumberValid: "",
  emailValid: "",
  acceptPolicyValid: "",
  messageValid: "",
  tourValid: "",
}
